@import "libs/icons";
@import "libs/magnificPopup.css";
@import "libs/animate.css";
@import "libs/owl.carousel.min.css";
@import "libs/datepicker.min.css";

.owl-product,.owl-related-products{
    .owl-stage {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;

        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .owl-item{
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: auto !important;
    }
}
