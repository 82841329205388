@font-face {
  font-family: 'iconpack';
  src:  url('../fonts/iconpack.eot?bhj6br');
  src:  url('../fonts/iconpack.eot?bhj6br#iefix') format('embedded-opentype'),
    url('../fonts/iconpack.ttf?bhj6br') format('truetype'),
    url('../fonts/iconpack.woff?bhj6br') format('woff'),
    url('../fonts/iconpack.svg?bhj6br#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
i.icon{
    font-family: 'iconpack' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.icon-home:before {
      content: "\e903";
    }
    &.icon-hotel:before {
      content: "\e900";
    }
    &.icon-arrow:before {
      content: "\e901";
    }
    &.icon-golf:before {
      content: "\e902";
    }
    &.icon-kongresy:before {
      content: "\e904";
    }
    &.icon-sport:before {
      content: "\e905";
    }
    &.icon-wellness:before {
      content: "\e906";
    }
    &.icon-bars:before {
      content: "\e907";
    }
    &.icon-cross:before {
      content: "\e908";
    }
}
